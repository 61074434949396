import { useTranslation } from 'next-i18next'
import { twMerge as cn } from 'tailwind-merge'

import ArrowDown from 'assets/icon/arrow_down.svg'
import PlayseeLogo from 'assets/icon/logo/playsee_logo_new.svg'
import {
  LEGAL_LINKS,
  REDIRECT_APP_LINK,
  PLAYSEE_LINKS,
  env
} from '../assets/constant'

const languages = [
  {
    key: 'ar',
    name: 'اللغة العربية'
  },
  {
    key: 'de',
    name: 'Deutsch'
  },
  {
    key: 'en',
    name: 'English'
  },
  {
    key: 'es',
    name: 'Español'
  },
  {
    key: 'fr',
    name: 'Français'
  },
  {
    key: 'hi',
    name: 'हिंदी'
  },
  {
    key: 'id',
    name: 'Bahasa Indonesia'
  },
  {
    key: 'it',
    name: 'Italiano'
  },
  {
    key: 'ja',
    name: '日本語'
  },
  {
    key: 'ko',
    name: '한국어'
  },
  {
    key: 'ms',
    name: 'Bahasa Melayu'
  },
  {
    key: 'pl',
    name: 'Polski'
  },
  {
    key: 'pt',
    name: 'Português'
  },
  {
    key: 'ru',
    name: 'Русский'
  },
  {
    key: 'th',
    name: 'ภาษาไทย'
  },
  {
    key: 'tr',
    name: 'Türkçe'
  },
  {
    key: 'vi',
    name: 'Tiếng Việt'
  },
  {
    key: 'zh-CN',
    name: '简体中文'
  },
  {
    key: 'zh-TW',
    name: '繁體中文'
  }
]

const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const handleChange = async (e: any) => {
    const lang = e.target.value
    window.location.href = `/${lang}`
  }

  return (
    <div className='relative'>
      <select
        onChange={handleChange}
        value={i18n.resolvedLanguage}
        className='border rounded-[12px] border-label-l3 py-[10px] pl-[14px] pr-[32px] text-ellipsis w-[160px] appearance-none bg-white'
      >
        {languages.map(language => (
          <option key={language.key} value={language.key}>
            {language.name}
          </option>
        ))}
      </select>
      <ArrowDown className='absolute w-[18px] h-[18px] top-[10px] right-[11px] pointer-events-none' />
    </div>
  )
}

const Footer = () => {
  const { t } = useTranslation()

  const contents = [
    {
      catalog: t('common.playsee_app'),
      links: [
        {
          name: t('common.download_app'),
          href: REDIRECT_APP_LINK
        },
        env !== 'master' && {
          name: t('common.business'),
          href: PLAYSEE_LINKS.business
        },
        {
          name: t('common.about'),
          href: PLAYSEE_LINKS.about
        },
        {
          name: t('common.careers'),
          href: PLAYSEE_LINKS.careers
        }
      ].filter(Boolean) as { name: string; href: string }[]
    },
    {
      catalog: t('common.resources'),
      links: [
        {
          name: t('common.help_center'),
          href: PLAYSEE_LINKS.help
        }
      ]
    },
    {
      catalog: t('common.legal'),
      links: [
        {
          name: t('common.playsee_rules'),
          href: LEGAL_LINKS.rules
        },
        {
          name: t('common.playsee_user_terms_of_service'),
          href: LEGAL_LINKS.terms
        },
        {
          name: t('common.additional_terms_of_service'),
          href: LEGAL_LINKS.adTerms
        },
        {
          name: t('common.playsee_privacy_notice'),
          href: LEGAL_LINKS.privacy
        }
      ]
    }
  ]

  return (
    <footer className='border-t border-b border-glass_special-separators homePad:py-[48px] homePad:flex homePad:justify-center'>
      <div className='homePad:flex homePad:px-[64px] homePad:justify-center w-full'>
        {/* Contents part */}
        <div className='flex flex-wrap px-[24px] py-[32px] footer-content-gap homePad:basis-[max-content] homePad:shrink homePad:order-1 homePad:p-0'>
          {contents.map((content, index) => (
            <div
              key={index}
              className={cn('flex flex-col w-max', index === 2 && 'col-span-2')}
            >
              <div className='text-playseeGray-gray3 font-semibold mb-[12px]'>
                {content.catalog}
              </div>
              <div className='flex flex-col space-y-[12px]'>
                {content.links.map((link, linkIndex) => (
                  <a
                    key={linkIndex}
                    href={link.href}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='text-playseeGray-gray1'
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Logo part */}
        <div className='flex mx-[24px] py-[32px] border-t border-glass_special-separators homePad:flex-col homePad:border-0 homePad:m-0 homePad:p-0'>
          <PlayseeLogo className='w-[118px] h-[36px] fill-label-l3' />
          <div className='flex flex-col ml-auto homePad:ml-0 homePad:mt-[24px]'>
            <LanguageSelector />
            <div className='ml-auto mt-[8px] homePad:ml-0 text-playseeGray-gray3'>
              ©{new Date().getFullYear()} Playsee
            </div>
          </div>
        </div>

        {/* Spacing */}
        <div className='hidden homePad:block basis-[640px] shrink-[1000000] grow max-w-[640px] min-w-[64px]'></div>
      </div>
    </footer>
  )
}

export default Footer
