import { appWithTranslation, useTranslation } from 'next-i18next'
import Head from 'next/head'
import { ElementRef, Fragment, useEffect, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'

import { CDN_URL, GTM_KEY, PLAYSEE_LINKS } from 'assets/constant'
import PlayFill from 'assets/icon/tab/play_f_big.svg'
import Play from 'assets/icon/tab/play_s_big.svg'
import SearchFill from 'assets/icon/tab/search_f_big.svg'
import Search from 'assets/icon/tab/search_s_big.svg'
import CommunityFill from 'assets/icon/tab/us_f_big.svg'
import Community from 'assets/icon/tab/us_s_big.svg'
import CookieHint from 'component/cookie-hint'
import Footer from 'component/footer'
import Header from 'component/header'
import 'intersection-observer'
import { AppProps } from 'next/app'
import './global.css'

const App = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation()

  const [leavedTop, setLeavedTop] = useState(false)
  const headerRef = useRef<ElementRef<'header'>>(null)
  const landingRef = useRef<ElementRef<'section'>>(null)

  const navLinks = [
    {
      href: `${PLAYSEE_LINKS.platform}spot`,
      title: t('common.spot'),
      Icon: Play,
      HoverIcon: PlayFill
    },
    {
      href: `${PLAYSEE_LINKS.platform}community`,
      title: t('common.community'),
      Icon: Community,
      HoverIcon: CommunityFill
    },
    {
      href: `${PLAYSEE_LINKS.platform}search`,
      title: t('common.search'),
      Icon: Search,
      HoverIcon: SearchFill
    }
  ]

  useEffect(() => {
    const header = headerRef.current
    const landing = landingRef.current
    if (!header || !landing) return

    const handleScroll = () => {
      if (window.scrollY > landing.offsetHeight - header.offsetHeight) {
        setLeavedTop(true)
      } else {
        setLeavedTop(false)
      }
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (GTM_KEY) {
      TagManager.initialize({ gtmId: GTM_KEY })
    }
  }, [])

  return (
    <Fragment>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'
        />
        <title>{t('landing_page.seo.title')}</title>
        <meta name='description' content={t('landing_page.seo.desc')} />
        <meta property='og:url' content={PLAYSEE_LINKS.about} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={t('landing_page.seo.title')} />
        <meta property='og:description' content={t('landing_page.seo.desc')} />
        <meta property='og:image' content={`${CDN_URL}/common/images/playsee_banner.jpg`} />
      </Head>

      <Header headerRef={headerRef} leavedTop={leavedTop} navLinks={navLinks} />
      <Component {...pageProps} landingRef={landingRef} />
      <Footer />
      <CookieHint />
    </Fragment>
  )
}

export default appWithTranslation(App)
