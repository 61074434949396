import { Content, Overlay, Portal, Root, Trigger } from '@radix-ui/react-dialog'
import { RefObject, useEffect, useState, type CSSProperties } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { twMerge as cn } from 'tailwind-merge'

import Logo from 'assets/icon/logo/playsee_logo_new.svg'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from 'component/ui//hover-card'
import { Button } from 'component/ui/button'

interface NavLink {
  href: string
  title: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  HoverIcon: React.FC<React.SVGProps<SVGSVGElement>>
}

interface NavButtonProps {
  navLinks: NavLink[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}
const NavButton = ({ navLinks, isOpen, setIsOpen }: NavButtonProps) => {
  const [isClient, setIsClient] = useState(false)
  const [device, setDevice] = useState<string | null>(null)

  useEffect(() => {
    let device = 'desktop'
    if (isTablet) device = 'tablet'
    else if (isMobile) device = 'mobile'

    setDevice(device)
    setIsClient(true)
  }, [])

  const hamburgerMenu = (
    <Button
      $variant='text-white'
      $icon={true}
      $size='sm'
      className={cn('hamburger hamburger--squeeze', isOpen && 'is-active')}
      title='Menu'
    >
      <div className='hamburger-box'>
        <div className='hamburger-inner' />
      </div>
    </Button>
  )

  if (!isClient) {
    return hamburgerMenu
  }

  if (device === 'desktop') {
    return (
      <HoverCard openDelay={0}>
        <HoverCardTrigger asChild={true}>{hamburgerMenu}</HoverCardTrigger>
        <HoverCardContent
          className='isolate w-[240px] overflow-hidden p-0'
          align='end'
          asChild
        >
          <nav>
            <ul className='flex flex-col text-label-l1'>
              {navLinks.map(({ href, title, HoverIcon, Icon }, index) => (
                <li key={index}>
                  <a
                    href={href}
                    title={title}
                    className='flex items-center space-x-[16px] px-[24px] py-[16px] hover:bg-background_elevated-2nd [&:hover>.tab-hover-icon]:block [&:hover>.tab-icon]:hidden'
                  >
                    <Icon className='tab-icon h-[32px] w-[32px] flex-shrink-0' />
                    <HoverIcon className='tab-hover-icon !ml-0 hidden h-[32px] w-[32px] flex-shrink-0' />
                    <span>{title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </HoverCardContent>
      </HoverCard>
    )
  }

  return (
    <Root open={isOpen} onOpenChange={setIsOpen} modal={true}>
      <Trigger asChild={true}>{hamburgerMenu}</Trigger>
      <Portal>
        <Overlay className='fixed bottom-0 left-0 right-0 top-0 z-0 bg-overlay-light backdrop-blur-[50px] duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0' />
        <Content asChild>
          <nav
            className={cn(
              'group fixed left-0 right-0 top-[64px] h-fit homePad:top-[128px]',
              'duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
              'flex justify-center pt-[24px] text-label_still-l1 outline-none'
            )}
          >
            <ul className='flex flex-col text-2xl'>
              {navLinks.map(({ href, title, Icon }, index) => (
                <li
                  key={index}
                  className={cn(
                    'w-[204px] px-[24px] py-[16px]',
                    'duration-200 group-data-[state=open]:animate-in group-data-[state=closed]:animate-out group-data-[state=closed]:slide-out-to-bottom-[--percent] group-data-[state=open]:slide-in-from-bottom-[--percent] '
                  )}
                  style={{ '--percent': `${index * -100}%` } as CSSProperties}
                >
                  <a
                    href={href}
                    title={title}
                    className='flex items-center space-x-[16px]'
                  >
                    <Icon className='tab-icon h-[32px] w-[32px] flex-shrink-0' />
                    <span>{title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </Content>
      </Portal>
    </Root>
  )
}

interface HeaderProps {
  leavedTop: boolean
  navLinks: NavLink[]
  headerRef: RefObject<HTMLElement>
}
const Header = ({ leavedTop, navLinks, headerRef }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <header
      ref={headerRef}
      className={cn(
        'fixed top-0 z-header flex px-[16px] h-[64px] w-full justify-between items-center homePad:px-[64px] homePad:h-[128px] text-[--text-color] header',
        leavedTop && !isOpen && 'header-white'
      )}
    >
      <Logo
        className='h-[24px] w-[80px] homePad:h-[36px] homePad:w-[118px]'
        onClick={() => {
          window.location.reload()
        }}
      />

      <NavButton navLinks={navLinks} isOpen={isOpen} setIsOpen={setIsOpen} />
    </header>
  )
}

export default Header
