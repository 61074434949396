import * as React from 'react'
import { twMerge as cn } from 'tailwind-merge'

export const Section = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) => {
  return (
    <section
      className={cn(
        'py-[48px] px-[24px] homePad:py-[96px] homePad:px-[32px]',
        className
      )}
      {...props}
    />
  )
}

export const SectionTitle = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h2
      className={cn(
        'text-[24px] leading-[30px] font-bold homePad:text-[60px] homePad:leading-[72px]',
        className
      )}
      {...props}
    >
      {children}
    </h2>
  )
}

export const SectionContent = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p
      className={cn('homePad:text-xl text-md', className)}
      {...props}
    />
  )
}

export const SectionLink = ({
  className,
  children,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a
      className={cn(
        'inline-block text-md homePad:text-lg font-semibold',
        className
      )}
      {...props}
    >
      {children}
    </a>
  )
}
