import { Trans, useTranslation } from 'next-i18next'
import CookieConsent from 'react-cookie-consent'

import { LEGAL_LINKS } from '../assets/constant'

const CookieHint = () => {
  const { t } = useTranslation()
  return (
    <CookieConsent
      containerClasses='cookies-hint'
      buttonText={t('common.ok')}
      disableStyles={true}
      cookieName='pca'
      location='none'
      contentClasses='cookies-text'
      buttonClasses='cookies-ok'
      buttonWrapperClasses=''
    >
      <Trans i18nKey='common.cookies_hint'>
        <a
          href={`${LEGAL_LINKS.privacy}#cookies`}
          title={t('common.cookie_policy')}
          target='_blank'
          rel='noopener noreferrer'
          className='px-[2px] font-semibold'
        >
          {t('common.cookie_policy')}
        </a>
        {{
          link: t('common.cookie_policy')
        }}
      </Trans>
    </CookieConsent>
  )
}

export default CookieHint
